<template>
	<div>
		<b-card title="EFIN List">
			<b-row>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Status:</label>
						<select 
							class="form-control"
							v-model="link"
							@change="getData"
						>
							<option value="pending">Pending</option>
							<option value="onprocess">On Proccess</option>
							<option value="success">Success</option>
						</select>
					</div>
				</b-col>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Name:</label>
						<input 
							type="text" 
							class="form-control"
							placeholder="Search: Nama"
							v-model="filter.name" 
						>
					</div>
				</b-col>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Phone:</label>
						<input 
							type="number" 
							class="form-control"
							placeholder="Search: Phone"
							v-model="filter.phone" 
						>
					</div>
				</b-col>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Email:</label>
						<input 
							type="email" 
							class="form-control"
							placeholder="Search: Email"
							v-model="filter.email" 
						>
					</div>
				</b-col>
				<b-col cols="12" lg="4">
					<div class="form-group">
						<label>Waktu Pengajuan:</label>
						<flat-pickr
							v-model="filter.requested_at"
							class="form-control"
							placeholder="Search: Waktu Pengajuan"
							:config="{ 
								mode: 'range',
								locale: {
									rangeSeparator: ' - ',
								},
							}"
						/>
					</div>
				</b-col>
			</b-row>
			<b-col cols="12" class="p-0 mt-2">
				<button 
					:disabled="!filter.name" 
					@click="filter.name = ''" 
					class="btn btn-outline-secondary mr-2"
				>
					Reset
				</button>
			</b-col>
			<div class="border__dash"></div>
			<b-table 
				striped 
				hover 
				:items="efins" 
				:fields="fields"
				:busy="isLoading"
				show-empty
				responsive
			>
				<template #table-busy>
					<div class="text-center text-secondary my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>

				<template v-slot:cell(no)="{ index }">
					{{ index + 1 }}
				</template>

				<template v-slot:cell(status)="{ item }">
					<b-badge
						variant="light-secondary"
						v-if="item.status == 'pending'"
					>
						Pending
					</b-badge>
					<b-badge
						variant="light-warning"
						v-if="item.status == 'onprocess'"
					>
						On Process
					</b-badge>
					<b-badge
						variant="light-primary"
						v-if="item.status == 'success'"
					>
						Success
					</b-badge>
				</template>

				<template v-slot:cell(waktu_pengajuan)="{ item }">
					{{ item.requested_at }}
				</template>
				
				<template v-slot:cell(actions)="{ item }">
					<div class="d-flex" style="gap:12px;">
						<b-badge 
							variant="primary"
							class="badge-glow d-flex align-items-center"
							@click="detailItem(item)"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Detail Data'"
							v-if="checkPermission('detail efin request')"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-send-icon`"
								icon="EditIcon"
								class="cursor-pointer"
								size="16"
							/>
							Detail
						</b-badge>
						
						<b-badge 
							variant="danger"
							class="badge-glow d-flex align-items-center"
							v-if="item.status == 'onprocess'"
							v-ripple.400="'rgba(113, 102, 240, 0.15)'"
							v-b-tooltip.hover.bottom="'Delete Data'"
							@click="SetToPending(item.uuid)"
						>
							<feather-icon
								:id="`invoice-row-${item.uuid}-preview-icon`"
								icon="ArrowUpIcon"
								size="16"
							/>
							Set to Pending
						</b-badge>

					</div>
				</template>
			</b-table>
			<div
				v-if="result.total > 0"
				class="m-1"
			>
				<div class="row">
					<div class="col mb-1">
						<small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
					</div>
					<div class="col">
						<pagination
							:data="result"
							:limit="4"
							align="right"
							@pagination-change-page="getData"
						/>
					</div>
				</div>
			</div>

			<b-modal
				id="form-efin-modal" 
				centered
				title="Detail Request"
				size="lg"
				no-close-on-backdrop
				hide-footer
			>
				<div class="text-center text-secondary my-2" v-if="isLoading">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>

				<ValidationObserver v-else>
					<div class="form-row">
						<div class="form-group col-md-6">
							<label>Name :</label>
							<validation-provider
								name="name"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Name" 
									v-model="formPayload.name"
									:disabled="formPayload.status == 'onprocess' || formPayload.status == 'success'"
								>
								<small
									v-for="(validation, index) in validations.name"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Email :</label>
							<validation-provider
								name="email"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Email" 
									v-model="formPayload.email"
									:disabled="formPayload.status == 'onprocess' || formPayload.status == 'success'"
								>
								<small
									v-for="(validation, index) in validations.email"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Phone :</label>
							<validation-provider
								name="number"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="number"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="Phone" 
									v-model="formPayload.phone"
									:disabled="formPayload.status == 'onprocess' || formPayload.status == 'success'"
								>
								<small
									v-for="(validation, index) in validations.phone"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>Address :</label>
							<validation-provider
								name="address"
								rules="required"
								v-slot="{ errors }"
							>
								<textarea 
									class="form-control"
									:state="errors.length > 0 ? false:null"
									placeholder="Address" 
									v-model="formPayload.address"
									:disabled="formPayload.status == 'onprocess' || formPayload.status == 'success'"
								/>
								<small
									v-for="(validation, index) in validations.address"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>NIK :</label>
							<validation-provider
								name="nik"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="number"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="NIK" 
									v-model="formPayload.nik"
									:disabled="formPayload.status == 'onprocess' || formPayload.status == 'success'"
								>
								<small
									v-for="(validation, index) in validations.nik"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<div class="form-group col-md-6">
							<label>NPWP :</label>
							<validation-provider
								name="npwp"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="text"
									class="form-control"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									placeholder="NPWP" 
									v-model="formPayload.npwp"
									:disabled="formPayload.status == 'onprocess' || formPayload.status == 'success'"
								>
								<small
									v-for="(validation, index) in validations.npwp"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
						<template v-if="formPayload.status == 'pending'">
							<div class="form-group col-md-6">
								<label>Gender :</label>
								<validation-provider
									name="email"
									rules="required"
									v-slot="{ errors }"
								>
									<div class="demo-inline-spacing" style="margin-top:8px;">
										<div class="custom-control custom-radio mt-0">
											<input 
												v-model="formPayload.gender"
												name="inlineRadioOptions" 
												class="custom-control-input"
												:state="errors.length > 0 ? false:null" 
												type="radio" 
												id="radio1" 
												value="male"
											>
											<label 
												class="custom-control-label" 
												for="radio1"
											>
												Pria
											</label>
										</div>
										<div class="custom-control custom-radio mt-0">
											<input 
												v-model="formPayload.gender"
												name="inlineRadioOptions" 
												class="custom-control-input" 
												:state="errors.length > 0 ? false:null"
												type="radio" 
												id="radio2"
												value="female"
											>
											<label 
												class="custom-control-label" 
												for="radio2"
											>
												Wanita
											</label>
										</div>
									</div>
									<small
										v-for="(validation, index) in validations.gender"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
							<div class="form-group col-md-6">
								<label>City :</label>
								<validation-provider
									name="city"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text"
										class="form-control"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										placeholder="City" 
										v-model="formPayload.city"
									>
									<small
										v-for="(validation, index) in validations.city"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
							<div class="form-group col-md-6">
								<label>District :</label>
								<validation-provider
									name="district"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text"
										class="form-control"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										placeholder="District" 
										v-model="formPayload.district"
									>
									<small
										v-for="(validation, index) in validations.district"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
							<div class="form-group col-md-6">
								<label>Village :</label>
								<validation-provider
									name="village"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text"
										class="form-control"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										placeholder="Village" 
										v-model="formPayload.village"
									>
									<small
										v-for="(validation, index) in validations.village"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
							<div class="form-group col-md-6">
								<label>Birthdate :</label>
								<validation-provider
									name="birthdate"
									rules="required"
									v-slot="{ errors }"
								>
									<flat-pickr
										v-model="formPayload.birth_date"
										:state="errors.length > 0 ? false:null"
										class="form-control"
									/>
									<small
										v-for="(validation, index) in validations.birth_date"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
							<div class="form-group col-md-6">
								<label>Birthplace :</label>
								<validation-provider
									name="birthplace"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text"
										class="form-control"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										placeholder="Birthplace" 
										v-model="formPayload.birth_place"
									>
									<small
										v-for="(validation, index) in validations.birth_place"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
						</template>
						<template v-if="formPayload.status == 'pending'">
							<div class="form-group col-md-6">
								<label>Email Pengirim :</label>
								<validation-provider
									name="smtp mail from"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text"
										class="form-control"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										placeholder="Email Pengirim" 
										v-model="formPayload.smtp_mail_from"
									>
									<small
										v-for="(validation, index) in validations.smtp_mail_from"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
							<div class="form-group col-md-6">
								<label>Password Email Pengirim :</label>
								<validation-provider
									name="smtp password"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text"
										class="form-control"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										placeholder="Password Email Pengirim" 
										v-model="formPayload.smtp_password"
									>
									<small
										v-for="(validation, index) in validations.smtp_password"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
							<div class="form-group col-md-6">
								<label>Server Email Pengirim Keluar :</label>
								<validation-provider
									name="smtp host"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text"
										class="form-control"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										placeholder="Server Email Pengirim Keluar" 
										v-model="formPayload.smtp_host"
									>
									<small
										v-for="(validation, index) in validations.smtp_host"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
							<div class="form-group col-md-6">
								<label>Port Email Pengirim Keluar :</label>
								<validation-provider
									name="smtp port"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text"
										class="form-control"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										placeholder="Port Email Pengirim Keluar" 
										v-model="formPayload.smtp_port"
									>
									<small
										v-for="(validation, index) in validations.smtp_port"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
							<div class="form-group col-md-12">
								<label>Email Kantor Pajak :</label>
								<validation-provider
									name="smtp mail to"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text"
										class="form-control"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										placeholder="Email Kantor Pajak" 
										v-model="formPayload.smtp_mail_to"
									>
									<small
										v-for="(validation, index) in validations.smtp_mail_to"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
						</template>
						<template v-else>
							<div class="form-group col-md-12">
								<label>EFIN :</label>
								<validation-provider
									name="smtp host"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text"
										class="form-control"
										:class="classes"
										:state="errors.length > 0 ? false:null"
										placeholder="EFIN" 
										v-model="formPayloadEfins.efin"
										:disabled="formPayload.status == 'success'"
									>
									<small
										v-for="(validation, index) in validations.efin"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
						</template>
						<template v-if="formPayload.status == 'pending'">
							<div class="form-group col-md-6">
								<label>Foto NPWP :</label>
								<br>
								<a :href="formPayload.filesNpwp" target="_blank">
									<img :src="formPayload.filesNpwp" width="200" height="200" alt="NPWP Image">
								</a>
							</div>
							<div class="form-group col-md-6">
								<label>Foto KTP :</label>
								<br>
								<a :href="formPayload.filesKtp" target="_blank">
									<img :src="formPayload.filesKtp" width="200" height="200" alt="KTP Image">
								</a>
							</div>
							<div class="form-group col-md-6">
								<label>Swafoto NPWP :</label>
								<br>
								<a :href="formPayload.filesSwafotoNpwp" target="_blank">
									<img :src="formPayload.filesSwafotoNpwp" width="200" height="200" alt="Swafoto NPWP Image">
								</a>
							</div>
							<div class="form-group col-md-6">
								<label>Swafoto KTP :</label>
								<br>
								<a :href="formPayload.filesSwafotoKtp" target="_blank">
									<img :src="formPayload.filesSwafotoKtp" width="200" height="200" alt="Swafoto KTP Image">
								</a>
							</div>
							<div class="form-group col-md-6">
								<label>Tanda Tangan :</label>
								<br>
								<a :href="formPayload.filesTtd" target="_blank">
									<img :src="formPayload.filesTtd" width="200" height="200" alt="Swafoto KTP Image">
								</a>
							</div>
							<div class="form-group col-md-6">
								<label>PDF :</label>
								<br>
								<a :href="formPayload.pdf" target="_blank">
									<div id="canvas_container">
										<canvas id="pdf_renderer"></canvas>
									</div>
								</a>
							</div>
						</template>
					</div>
					<template v-if="isLoading">
						<b-button 
							variant="primary"
						>
							<b-spinner
								variant="secondary"
								disabled
							/>
						</b-button>
					</template>
					<template v-else>
						<b-button
							v-if="formPayload.status == 'pending' && checkPermission('update efin request')"
							variant="secondary"
							class="mr-1"
							@click="updateForm()"
						>
							Simpan
						</b-button>
						<b-button 
							v-if="formPayload.status == 'pending' && checkPermission('update efin request')"
							variant="primary" 
							@click="updateToProcess()" 
						>
							Set to ON PROCESS
						</b-button>
						<b-button 
							v-if="formPayload.status == 'onprocess' & checkPermission('update efin request')"
							variant="primary" 
							@click="updateToDone()" 
						>
							Set to DONE
						</b-button>
					</template>
				</ValidationObserver>
			</b-modal>
		</b-card>
	</div>
</template>

<script>
import { 
	BCard,
	BTable,
	BPagination,
	BFormFile,
	BButton,
	BSpinner,
	BBadge,
	BRow,
	BCol,
	VBTooltip
} from 'bootstrap-vue'
import _ from 'lodash'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import Detail from '@/components/EFIN/Detail.vue'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BTable,
		BPagination,
		BFormFile,
		BButton,
		BSpinner,
		BBadge,
		BRow,
		BCol,
		Detail,
		vSelect,
		flatPickr,
		ValidationProvider,
		ValidationObserver
	},
	directives: {
	'b-tooltip': VBTooltip,
		Ripple,
	},
	watch: {
		filter: {
			handler: _.debounce(function () {
				this.getData()
			}, 300),
			deep: true,
		},
	},
	setup(props) {
		return {
			checkPermission,
			errorNotification,
			successNotification,
		}
	},
	data() {
		return {
			required,
			currentPage: 1,
			result: {},
			isLoading: false,
			fields: ['no' ,'name', 'email', 'phone', 'waktu_pengajuan', 'status', 'actions'],
			efins: [],
			link: 'pending',
			filter: {
				name: '',
				phone: '',
				email: '',
				requested_at: ''
			},
			formPayload: Object,
			formPayloadEfins: Object,
			pages:[],
			displayInput: '',
			// uuid: '',
			photo_url: null,
			editId: null,
			myState : {
				pdf: null,
				currentPage: 1,
				zoom: 0.4
			},
			validations: '',
		}
	},
	computed: {
		rows() {
			return this.efins.length
		},
	},
	methods: {
		modalId(id) {
			return 'modal_' + id;
		},
		cleanUpForm() {
			this.editId = null,
			this.validations = ''
		},
		preparePayload() {
			const form = new FormData()
			if (this.formPayload.status == 'pending') {
				for (const key in this.formPayload) {
					if (this.formPayload.hasOwnProperty(key)) {	
						if (key != 'pdf' && key != 'filesNpwp' && key != 'filesKtp' && key != 'filesSwafotoNpwp' && key != 'filesSwafotoKtp' && key != 'filesTtd'){
							form.append(key, this.formPayload[key])
						}
					}
				}
			} else {
				for (const key in this.formPayloadEfins) {
					if (this.formPayloadEfins.hasOwnProperty(key)) {
						form.append(key, this.formPayloadEfins[key])	
					}
				}
			}

			return form
		},
		detailItem(item) {
			this.cleanUpForm();
			this.isLoading = true
			this.editId = item.uuid
			this.$http.get('admin/efins/detail/' + this.editId)
			.then(response => {
				this.formPayload = {
					name			: response.data.data.name,
					email			: response.data.data.email,
					phone			: response.data.data.phone,
					address			: response.data.data.address,
					city			: response.data.data.city,
					district		: response.data.data.district,
					village			: response.data.data.village,
					gender 			: response.data.data.gender,
					nik 			: response.data.data.nik,
					npwp 			: response.data.data.npwp,
					birth_date 		: response.data.data.birth_date,
					birth_place 	: response.data.data.birth_place,
					smtp_host 		: response.data.data.smtp_host,
					smtp_port 		: response.data.data.smtp_port,
					smtp_mail_from 	: response.data.data.smtp_mail_from,
					smtp_mail_to	: response.data.data.smtp_mail_to,
					smtp_password	: response.data.data.smtp_password,
					status			: response.data.data.status,
					requested_at	: response.data.data.requested_at,
					pdf				: response.data.data.pdf,
					filesNpwp		: response.data.data.files.npwp,
					filesKtp		: response.data.data.files.ktp,
					filesSwafotoNpwp: response.data.data.files.swafoto_npwp,
					filesSwafotoKtp	: response.data.data.files.swafoto_ktp,
					filesTtd		: response.data.data.files.ttd,
				}
				this.formPayloadEfins = {
					efin			: response.data.data.efin,
				}
				pdfjsLib.getDocument(this.formPayload.pdf).then((pdf) => {
					this.myState.pdf = pdf;
					this.render();
				});
			}),
			this.isLoading = false
			this.$bvModal.show('form-efin-modal')
		},
		render() {
            this.myState.pdf.getPage(this.myState.currentPage).then((page) => {
                var canvas = document.getElementById("pdf_renderer");
                var ctx = canvas.getContext('2d');
                var viewport = page.getViewport(this.myState.zoom);

                canvas.width = viewport.width;
                canvas.height = viewport.height;

                page.render({
                    canvasContext: ctx,
                    viewport: viewport
                });
            });
        },
		updateToProcess() {
			var form = this.preparePayload();
			this.isLoading = true
			this.$http.post(`admin/efins/set-to-on-process/${this.editId}`, form, {
				headers: { 'content-type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-efin-modal')
				this.getData()
				successNotification(this, 'Success', 'Berhasil mengubah status!')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.messages || error.response.data.meta.validations) {
					errorNotification(this, 'Oops!', error.response.data.meta.messages)          
				} 
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					// errorNotification(this, 'Oops', this.validations.name.join(' '))       
				}
				this.isLoading = false
			})
		},
		updateForm() {
			var form = this.preparePayload();
			form.append("_method", 'PUT')
			this.isLoading = true
			this.$http.post(`admin/efins/update/${this.editId}`, form, {
				headers: { 'content-type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-efin-modal')
				this.getData()
				successNotification(this, 'Success', 'Berhasil mengubah form!')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.messages) {
					this.validations = error.response.data.meta.messages
					errorNotification(this, 'Oops!', error.response.data.meta.messages)          
				}
				this.isLoading = false
			})
		},
		updateToDone() {
			var form = this.preparePayload();
			this.isLoading = true
			this.$http.post(`admin/efins/set-to-done/${this.editId}`, form, {
				headers: { 'content-type': 'multipart/form-data' }
			})
			.then(response => {
				this.$bvModal.hide('form-efin-modal')
				this.getData()
				successNotification(this, 'Success', 'Berhasil mengubah status!')
				this.cleanUpForm();
				this.isLoading = false
			})
			.catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'EFIN', this.validations.efin.join(' '))       
				}
				this.isLoading = false
			})
		},
		getData(page) { //page = 1
			this.isLoading = true
			let url = this.link
			const queryParams = this.filter
			queryParams.per_page = 10
			queryParams.page = page
			this.$http.get('admin/efins/' + url ,
			{
				params: queryParams,
			}).then(response => {
				this.result = response.data.data
				this.efins = response.data.data.data
				this.isLoading = false
			})
		},
		SetToPending(item) {
			this.$swal({
				title: 'Apakah Anda yakin?',
				icon: 'info',
				text: 'Untuk merubah status EFIN menjadi Pending?.',
				type: 'warning',
				showCancelButton: true,
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				confirmButtonText: 'Set to Pending',
				cancelButtonText: 'Batal',
				buttonsStyling: false
			}).then(async result =>{
				if(result.value) {
					await this.$http.post('admin/efins/set-to-pending/' + item)
					this.getData()
					successNotification(this, 'Success', 'Status EFIN berhasil diubah!')
				}
			}).catch(error => {
				errorNotification(this, 'Oops!', 'Ada Kendala Teknis')
			})
		}
	},
	created() {
		this.getData();
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>